.page-transition {
  position: relative;
}

.page-transition > * {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.page-transition > *:nth-child(1) {
  opacity: 1;
  transform: translateX(0);
}

img {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.html {
  max-width: 42rem;
}

* {
  overscroll-behavior-y: none;
}
